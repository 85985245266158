export const images = [
    {src: "../images/tshirt 1.jpg", alt: "tshirt quilt"},
    {src: "../images/tshirt 2.jpg", alt: "tshirt quilt"},

    {src: "../images/l-quilt 1.jpg", alt: "Lounge quilt"},
    {src: "../images/l-quilt 2.jpg", alt: "Lounge quilt"},

    {src: "../images/babyQuilt 1.jpg", alt: "Baby quilt"},
    {src: "../images/babyQuilt 2.jpg", alt: "Baby quilt"},

    {src: "../images/bedQuilt 3.jpg", alt: "Bed quilt"},
    {src: "../images/bedQuilt 4.jpg", alt: "Bed quilt"}
]